:root {
  --darkGreen: #005034;
  --lightGreen: #e2f6c9;
  --lemonGreen: #86bc42;
}

body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

nav.stripped-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--lightGreen);
  z-index: 99;
  @apply opacity-100
}

nav::after {
  background-color: rgba(255, 255, 255,1);
  content: "";
  @apply opacity-100
}

/* navigation */
.hamburger {
  cursor: pointer;
  width: 22px;
  height: 22px;
  transition: all 0.25s;
  position: relative;
  background-color: inherit;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 22px;
  height: 4px;
  top: 0;
  left: 0;
  transform: rotate(0);
  transition: all 0.5s;
  background-color: var(--darkGreen);
}

.hamburger-middle {
  transform: translateY(8px);
}

.hamburger-bottom {
  transform: translateY(16px);
}

.open {
  transform: rotate(90deg);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}


.hero {
  @apply h-[calc(90vh-90px)] md:h-[calc(90vh-70px)] 
}

.hero--background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 40px;
  z-index: -3;
  height: 100%;
  width: var(--width);
  border-bottom-right-radius: 35px;
  @apply bg-gradient-to-br from-white from-20% via-lightGreen-100 via-40% to-lemonGreen-80 to-100%
}


.bg-gradient-akigreen {
  @apply bg-gradient-to-br from-white from-10% via-lightGreen-100 via-30% to-lemonGreen-100 to-80%
}

.bg-gradient-akigreen2 {
  @apply bg-gradient-to-r from-lemonGreen-80 from-30% via-lightGreen-100 via-90% to-white to-100%
}

.map-section .gmap-frame #gmap-canvas {
  height: 100%;
  width: 100%;
}